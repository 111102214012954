<template>
  <v-card>
    <v-toolbar>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ type === 'F' ? 'Foundation' : 'College' }} Board Documents
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :disabled="!collegeAccess || type === 'C'" @click="type = 'C'">
            <v-list-item-title>College Board Documents</v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="!foundationAccess || type === 'F'" @click="type = 'F'">
            <v-list-item-title>Foundation Board Documents</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ selectedTerm }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card style="max-height:500px">
          <v-list>
            <template v-for="({ year, terms: termList }, index) in terms">
              <v-subheader :key="'sub-' + index">{{ year }}</v-subheader>
              <v-list-item v-for="{ _id, term } in termList" :key="_id" @click="select({ _id, term })">
                <v-list-item-title>{{ term }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>
      <v-dialog v-if="isAdmin" v-model="newTermDialog" width="300">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined>
            <v-icon left>fal fa-plus</v-icon>
            Add New Term
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add New Term</v-card-title>
          <v-card-text>
            <v-text-field v-model="newTerm" label="Term Name"></v-text-field>
            <v-text-field v-model="newAcadYear" label="Academic Year"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="newTermDialog = false">Cancel</v-btn>
            <v-btn text color="success" @click="saveNewTerm()">Add Term</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text v-if="col1.length === 0 && col2.length === 0">This is where admins can add new academic years, terms, and files to those terms.</v-card-text>
    <v-card-text>
      <v-row>
        <v-col :cols="12" md="6" lg="5" offset-lg="1">
          <group-card v-for="({ label, files }, index) in col1" :key="'col1-' + index" :label="label" :files="files" :folder-id="folderId" @update="(obj) => update(1, index, obj)" @remove="remove(1, index)" @add="(obj) => addToGroup({ ...obj, col: 1, cardIndex: index })"></group-card>
          <v-btn color="success" @click="addGroup(col1)">
            <v-icon left>fal fa-plus</v-icon>
            Add File Group
          </v-btn>
        </v-col>
        <v-col :cols="12" md="6" lg="5">
          <group-card v-for="({ label, files }, index) in col2" :key="'col1-' + index" :label="label" :files="files" :folder-id="folderId" @update="(obj) => update(2, index, obj)" @remove="remove(2, index)" @add="(obj) => addToGroup({ ...obj, col: 2, cardIndex: index })"></group-card>
          <v-btn color="success" @click="addGroup(col2)">
            <v-icon left>fal fa-plus</v-icon>
            Add File Group
          </v-btn>
        </v-col>
        <v-col :cols="12">
          <v-card>
            <v-toolbar>
              <v-toolbar-title>Google Drive Folder</v-toolbar-title>
              <span style="margin:5px 0 0 5px">{{ unlinkedFiles.length }} unlinked files</span>
              <v-spacer></v-spacer>
              <v-btn v-if="folderId" :href="'https://drive.google.com/drive/u/0/folders/' + folderId" target="_blank" icon>
                <v-icon>fal fa-external-link</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list>
              <v-list-item v-for="({ id, mimeType, name }, fileIndex) in unlinkedFiles" :key="'ulfile-' + fileIndex">
                <v-list-item-avatar>
                  <v-icon v-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ name }}</v-list-item-title>
                <v-list-item-action>
                  <v-menu v-if="fileGroups.length > 0">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>fal fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item v-for="({ col, label, cardIndex }, groupIndex) in fileGroups" :key="'ulfile-' + fileIndex + '-group-' + groupIndex" @click="addToGroup({ col, cardIndex, fileIndex, id, name, mimeType })">
                        <v-list-item-title>Add to "{{ label }}"</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
export default {
  components: {
    GroupCard: () => import('./fileGroupCard')
  },
  props: {
    collegeAccess: {
      type: Boolean,
      default: false
    },
    foundationAccess: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => {
      return user.value.username === 'jon.moon'
    })
    const type = ref(props.collegeAccess ? 'C' : 'F')

    const selectedId = computed(() => root.$store.state.board.selectedId)
    const selectedTerm = computed(() => root.$store.state.board.selectedTerm || 'Select Term')
    const terms = ref([])
    function select ({ _id, term }) {
      root.$store.commit('board/setSelectedTerm', { term, id: _id })
    }
    const col1 = ref([])
    const col2 = ref([])
    const fileGroups = computed(() => {
      let arr = []
      for (let cardIndex = 0; cardIndex < col1.value.length; cardIndex++) {
        const { label } = col1.value[cardIndex]
        arr.push({ col: 1, label, cardIndex })
      }
      for (let cardIndex = 0; cardIndex < col2.value.length; cardIndex++) {
        const { label } = col2.value[cardIndex]
        arr.push({ col: 2, label, cardIndex })
      }
      return arr
    })

    const folderId = ref(null)
    const unlinkedFiles = ref([])

    const service = root.$feathers.service('board/files')

    watch(selectedId, (id) => {
      if (id != null) {
        unlinkedFiles.value = []
        service.get(id).then((data) => {
          col1.value = data.col1
          if ('col2' in data) col2.value = data.col2
          else col2.value = []
        })
        root.$feathers.service('board/drive-file').find({ query: { term: selectedTerm.value, type: type.value } }).then((data) => {
          folderId.value = data.folder
          for (const { id, mimeType, name } of data.files) {
            let included = false
            for (const { files } of col1.value) {
              if (files) {
                for (const { path } of files) {
                  if (id === path) {
                    included = true
                    break
                  }
                }
                if (included) break
              }
            }
            if (!included) {
              for (const { files } of col2.value) {
                if (files) {
                  for (const { path } of files) {
                    if (id === path) {
                      included = true
                      break
                    }
                  }
                  if (included) break
                }
              }
            }
            if (!included) {
              unlinkedFiles.value.push({ id, mimeType, name })
            }
          }
        })
      }
    })

    watch(type, async (val, oldval) => {
      if (oldval == null || val !== oldval) {
        col1.value = []
        col2.value = []
        root.$store.commit('board/setSelectedTerm', { term: null, id: null })
        const aggregate = [
          { $match: { type: type.value, year: { $ne: 'Minutes' } } },
          { $sort: { year: 1, term: 1 } },
          { $group: {
            _id: '$year',
            terms: {
              $push: { _id: '$_id', term: '$term' }
            }
          } },
          { $project: {
            year: '$_id',
            terms: { $sortArray: { input: '$terms', sortBy: { term: -1 } } }
          } },
          { $sort: { year: -1 } }
        ]
        const { data } = await service.find({ query: { aggregate } })
        terms.value = data
        if (selectedTerm.value === 'Select Term' && selectedId.value == null) {
          select(data[0].terms[0])
        }
      }
    })

    const newTermDialog = ref(false)
    const newTerm = ref('')
    const newAcadYear = ref('')
    function saveNewTerm () {
      if (newTerm.value !== '' && newAcadYear.value !== '') {
        service.create({
          year: newAcadYear.value,
          term: newTerm.value,
          type: type.value,
          col1: [{
            label: 'Main Files',
            files: []
          }],
          col2: [{
            label: 'Committee Files',
            files: []
          }, {
            label: 'Other Files',
            files: []
          }]
        }).then((data) => {
          let included = false
          for (let i = 0; i < terms.value.length; i++) {
            if (terms.value[i].year === data.year) {
              let temp = Object.assign({}, terms.value[i])
              temp.terms.push(data)
              terms.value.splice(i, 1, temp)
              included = true
            }
          }
          if (!included) {
            terms.value.push({ year: data.year, terms: [{ _id: data._id, year: data.year, term: data.term }] })
          }
          newTermDialog.value = false
        })
      }
    }

    function addGroup (col) {
      col.push({ label: 'New Group', files: [] })
    }

    function update (col, index, obj) {
      if (col === 1) {
        let temp = { ...col1.value[index] }
        for (let field in obj) temp[field] = obj[field]
        col1.value.splice(index, 1, temp)
        service.patch(selectedId.value, { col1: col1.value })
      } else if (col === 2) {
        let temp = { ...col2.value[index] }
        for (let field in obj) temp[field] = obj[field]
        col2.value.splice(index, 1, temp)
        service.patch(selectedId.value, { col2: col2.value })
      }
    }

    function remove (colNum, index) {
      if (colNum === 1) {
        col1.value.splice(index, 1)
        service.patch(selectedId.value, { col1: col1.value })
      } else if (colNum === 2) {
        col2.value.splice(index, 1)
        service.patch(selectedId.value, { col2: col2.value })
      }
    }
    function addToGroup ({ col, cardIndex, fileIndex, id, name, mimeType }) {
      let regex = /\.(pdf|xlsx|zip)$/
      if (regex.test(name)) {
        name = name.substr(0, name.lastIndexOf('.'))
      }
      if (col === 1) {
        const temp = Object.assign({}, col1.value[cardIndex])
        if (!('files' in temp)) temp.files = []
        temp.files.push({ name, mimeType, path: id })
        col1.value.splice(cardIndex, 1, temp)
        service.patch(selectedId.value, { $push: { ['col1.' + cardIndex + '.files']: { name, mimeType, path: id } } })
      } else if (col === 2) {
        const temp = Object.assign({}, col2.value[cardIndex])
        if (!('files' in temp)) temp.files = []
        temp.files.push({ name, mimeType, path: id })
        col2.value.splice(cardIndex, 1, temp)
        service.patch(selectedId.value, { $push: { ['col2.' + cardIndex + '.files']: { name, mimeType, path: id } } })
      }
      if (fileIndex) unlinkedFiles.value.splice(fileIndex, 1)
    }

    return {
      user,
      isAdmin,
      type,
      selectedTerm,
      terms,
      select,
      col1,
      col2,
      fileGroups,
      folderId,
      unlinkedFiles,
      newTermDialog,
      newTerm,
      newAcadYear,
      saveNewTerm,
      addGroup,
      update,
      remove,
      addToGroup
    }
  }
}
</script>
