var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.type === 'F' ? 'Foundation' : 'College')+" Board Documents "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":!_vm.collegeAccess || _vm.type === 'C'},on:{"click":function($event){_vm.type = 'C'}}},[_c('v-list-item-title',[_vm._v("College Board Documents")])],1),_c('v-list-item',{attrs:{"disabled":!_vm.foundationAccess || _vm.type === 'F'},on:{"click":function($event){_vm.type = 'F'}}},[_c('v-list-item-title',[_vm._v("Foundation Board Documents")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.selectedTerm)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-card',{staticStyle:{"max-height":"500px"}},[_c('v-list',[_vm._l((_vm.terms),function(ref,index){
var year = ref.year;
var termList = ref.terms;
return [_c('v-subheader',{key:'sub-' + index},[_vm._v(_vm._s(year))]),_vm._l((termList),function(ref){
var _id = ref._id;
var term = ref.term;
return _c('v-list-item',{key:_id,on:{"click":function($event){return _vm.select({ _id: _id, term: term })}}},[_c('v-list-item-title',[_vm._v(_vm._s(term))])],1)})]})],2)],1)],1),(_vm.isAdmin)?_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add New Term ")],1)]}}],null,false,3422019902),model:{value:(_vm.newTermDialog),callback:function ($$v) {_vm.newTermDialog=$$v},expression:"newTermDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add New Term")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Term Name"},model:{value:(_vm.newTerm),callback:function ($$v) {_vm.newTerm=$$v},expression:"newTerm"}}),_c('v-text-field',{attrs:{"label":"Academic Year"},model:{value:(_vm.newAcadYear),callback:function ($$v) {_vm.newAcadYear=$$v},expression:"newAcadYear"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newTermDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.saveNewTerm()}}},[_vm._v("Add Term")])],1)],1)],1):_vm._e()],1),(_vm.col1.length === 0 && _vm.col2.length === 0)?_c('v-card-text',[_vm._v("This is where admins can add new academic years, terms, and files to those terms.")]):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6","lg":"5","offset-lg":"1"}},[_vm._l((_vm.col1),function(ref,index){
var label = ref.label;
var files = ref.files;
return _c('group-card',{key:'col1-' + index,attrs:{"label":label,"files":files,"folder-id":_vm.folderId},on:{"update":function (obj) { return _vm.update(1, index, obj); },"remove":function($event){return _vm.remove(1, index)},"add":function (obj) { return _vm.addToGroup(Object.assign({}, obj, {col: 1, cardIndex: index})); }}})}),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addGroup(_vm.col1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add File Group ")],1)],2),_c('v-col',{attrs:{"cols":12,"md":"6","lg":"5"}},[_vm._l((_vm.col2),function(ref,index){
var label = ref.label;
var files = ref.files;
return _c('group-card',{key:'col1-' + index,attrs:{"label":label,"files":files,"folder-id":_vm.folderId},on:{"update":function (obj) { return _vm.update(2, index, obj); },"remove":function($event){return _vm.remove(2, index)},"add":function (obj) { return _vm.addToGroup(Object.assign({}, obj, {col: 2, cardIndex: index})); }}})}),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addGroup(_vm.col2)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add File Group ")],1)],2),_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Google Drive Folder")]),_c('span',{staticStyle:{"margin":"5px 0 0 5px"}},[_vm._v(_vm._s(_vm.unlinkedFiles.length)+" unlinked files")]),_c('v-spacer'),(_vm.folderId)?_c('v-btn',{attrs:{"href":'https://drive.google.com/drive/u/0/folders/' + _vm.folderId,"target":"_blank","icon":""}},[_c('v-icon',[_vm._v("fal fa-external-link")])],1):_vm._e()],1),_c('v-list',_vm._l((_vm.unlinkedFiles),function(ref,fileIndex){
var id = ref.id;
var mimeType = ref.mimeType;
var name = ref.name;
return _c('v-list-item',{key:'ulfile-' + fileIndex},[_c('v-list-item-avatar',[(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(name))]),_c('v-list-item-action',[(_vm.fileGroups.length > 0)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.fileGroups),function(ref,groupIndex){
var col = ref.col;
var label = ref.label;
var cardIndex = ref.cardIndex;
return _c('v-list-item',{key:'ulfile-' + fileIndex + '-group-' + groupIndex,on:{"click":function($event){return _vm.addToGroup({ col: col, cardIndex: cardIndex, fileIndex: fileIndex, id: id, name: name, mimeType: mimeType })}}},[_c('v-list-item-title',[_vm._v("Add to \""+_vm._s(label)+"\"")])],1)}),1)],1):_vm._e()],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }